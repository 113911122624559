<template>
  <CardLayout title="History">
    <v-timeline v-if="taskHistory && taskHistory.length" dense>
      <v-timeline-item
        v-for="item in taskHistory"
        :key="item.id"
        :color="getTimelineColor(item.operation)"
        small
      >
        <v-card class="elevation-1">
          <v-card-title class="subtitle-2 d-flex align-center">
            <CardUserAvatar :user="item.user" />
            {{ formatUserName(item.user) }}
            {{ getOperationText(item.operation) }}
            {{
              item.operation === 0
                ? "the task"
                : `the ${formatFieldName(Object.keys(item.changeset)[0])}`
            }}
            <span class="text-caption grey--text ml-2">
              {{ formatDateTime4Humans(item.insertedAt) }}
            </span>
          </v-card-title>
          <v-card-text>
            <div v-if="item.changeset">
              <div
                v-for="(change, field) in filteredChangeset(item.changeset)"
                :key="field"
              >
                <strong>{{ formatFieldName(field) }}: </strong>
                <span :style="getStatusColor(change.old)" class="pa-1">{{
                  titleCase(change.old) || "(empty)"
                }}</span>
                <v-icon small>mdi-arrow-right-thin</v-icon>
                <span :style="getStatusColor(change.new)" class="pa-1">{{
                  titleCase(change.new) || "(empty)"
                }}</span>
              </div>
            </div>
            <div v-else-if="item.operation === 0">Task created</div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-alert v-else type="info" outlined>
      No history available for this task.
    </v-alert>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import CardUserAvatar from "@components/common/CardUserAvatar.vue";
import { TASK_HISTORY } from "./graphql";
import { titleCase } from "@tod-ui/helpers/strings";
import { formatName } from "./helper";
import { formatDateTime4Humans } from "@tod-ui/helpers/datetime";
import useUser from "@components/authentication/useUser";
import { RESOLUTION_STATUS } from "@tod-ui/constants/task_management";

export default {
  name: "CardTaskHistory",
  components: {
    CardLayout,
    CardUserAvatar,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    return { defaultOrganization };
  },
  apollo: {
    taskHistory: {
      query: TASK_HISTORY,
      variables() {
        return { taskId: this.taskId };
      },
    },
  },
  methods: {
    getTimelineColor(operation) {
      return operation === 0 ? "success" : "primary";
    },
    getOperationText(operation) {
      return operation === 0 ? "created" : "updated";
    },
    formatFieldName(field) {
      if (!field) {
        return "";
      }
      if (field.toLowerCase() === "resolution") {
        return "Status";
      }
      return field
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    filteredChangeset(changeset) {
      const filtered = { ...changeset };
      delete filtered.updated_at;
      delete filtered.events;
      return filtered;
    },
    titleCase,
    formatUserName(user) {
      return formatName(user);
    },
    formatDateTime4Humans(date) {
      return formatDateTime4Humans(date, this.defaultOrganization.time);
    },
    getStatusColor(status) {
      let color = "";
      switch (status) {
        case RESOLUTION_STATUS.TODO:
          color = "105,189,217";
          break;
        case RESOLUTION_STATUS.IN_PROGRESS:
          color = "255,152,0";
          break;
        case RESOLUTION_STATUS.IN_REVIEW:
          color = "156,39,176";
          break;
        case RESOLUTION_STATUS.DONE:
          color = "66,171,86";
          break;
        case RESOLUTION_STATUS.CANCELLED:
          color = "158,158,158";
          break;
      }
      return color
        ? {
            backgroundColor: `rgba(${color}, 0.2)`,
            color: `rgb(${color})`,
          }
        : {};
    },
  },
};
</script>
