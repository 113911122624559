<template>
  <v-row>
    <v-col>
      <CardComparisonTile
        heading="Open Incidents"
        :head-value="monitoringControlHubStats?.openIncidents?.toString()"
        top-title="Current"
        :top-value="monitoringControlHubStats?.currentIncidents?.toString()"
        bottom-title="Overdue"
        :bottom-value="monitoringControlHubStats?.overdueIncidents?.toString()"
        avatar-color="rgb(105,189,217)"
        top-color="green"
        bottom-color="red"
        avatar-name="open"
        :loading="loading"
        @headClickEvent="
          (event) => handleHeadClickEvent('openIncident', 'open')
        "
        @topClickEvent="(event) => handleTopClickEvent('openIncident', 'open')"
        @bottomClickEvent="
          (event) => handleBottomClickEvent('openIncident', 'open')
        "
      />
    </v-col>
    <v-col>
      <CardComparisonTile
        heading="In Progress Incidents"
        :head-value="monitoringControlHubStats?.inProgressIncidents?.toString()"
        top-title="In Review"
        :top-value="monitoringControlHubStats?.inReviewIncidents?.toString()"
        bottom-title="Overdue"
        :bottom-value="
          monitoringControlHubStats?.inProgressOverdueIncidents?.toString()
        "
        avatar-color="rgba(249, 203, 0, 1)"
        top-color="green"
        bottom-color="red"
        avatar-name="inprogress"
        :loading="loading"
        @headClickEvent="
          (event) => handleHeadClickEvent('inprogressIncident', 'in_progress')
        "
        @topClickEvent="
          (event) => handleTopClickEvent('inprogressIncident', 'in_review')
        "
        @bottomClickEvent="
          (event) => handleBottomClickEvent('inprogressIncident', 'in_progress')
        "
      />
    </v-col>
    <v-col>
      <CardComparisonTile
        heading="Open Tasks"
        :head-value="monitoringControlHubStats?.openTasks?.toString()"
        top-title="In Progress"
        :top-value="monitoringControlHubStats?.inProgressTasks?.toString()"
        bottom-title="Overdue"
        :bottom-value="monitoringControlHubStats?.overdueTasks?.toString()"
        avatar-color="#D50D41"
        top-color="green"
        bottom-color="red"
        avatar-name="task"
        :loading="loading"
        @headClickEvent="(event) => handleHeadClickEvent('opentask', 'todo')"
        @topClickEvent="
          (event) => handleTopClickEvent('opentask', 'in_progress')
        "
        @bottomClickEvent="
          (event) => handleBottomClickEvent('opentask', 'todo')
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import CardComparisonTile from "./CardComparisonTile.vue";
import { MONITORING_CONTROL_HUB_STATS_ITEM } from "./graphql";
import { ref } from "vue";
import useUser from "@components/authentication/useUser";

export default {
  name: "MonitorTileCards",
  components: {
    CardComparisonTile,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const loading = ref(true);
    const monitoringControlHubStats = ref(null);
    return { defaultOrganization, loading, monitoringControlHubStats };
  },
  apollo: {
    monitoringControlHubStats: {
      query: MONITORING_CONTROL_HUB_STATS_ITEM,
      variables() {
        return { organizationIds: [this.defaultOrganization.id] };
      },
      result({ data }) {
        if (data) {
          this.monitoringControlHubStats = data.monitoringControlHubStats;
          this.loading = false;
        }
      },
    },
  },
  methods: {
    handleHeadClickEvent(event, status) {
      if (event === "opentask") {
        this.$router.push({
          name: "ManageTasks",
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
            overdue: false,
          },
        });
      } else if (event === "openIncident") {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
          },
        });
      } else {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
          },
        });
      }
    },
    handleTopClickEvent(event, status) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ManageTasks",
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
          },
        });
      } else if (event === "openIncident") {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "today",
          },
        });
      } else {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
          },
        });
      }
    },
    handleBottomClickEvent(event, status) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ManageTasks",
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
            overdue: true,
          },
        });
      } else if (event === "openIncident") {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
            overdue: true,
          },
        });
      } else {
        this.$router.push({
          name: "PageIncidentDispositionReport",
          params: {
            status: event,
          },
          query: {
            status: status,
            from: "control_hub_dashboard",
            date: "all",
            overdue: true,
          },
        });
      }
    },
  },
};
</script>
