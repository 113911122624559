<template>
  <LayoutPage
    :title="`${defaultOrganizationName} Monitoring & Control Hub Dashboard`"
    :loading="loading"
  >
    <div>
      <div v-if="showRegionFacilitySelectBoxes">
        <RegionAndFacilitySelect over-view-page class="mt-2 mx-2" />
      </div>

      <MonitorTileCards />
    </div>

    <v-row>
      <v-col>
        <v-card v-if="!loading" outlined flat tile class="black-border">
          <v-card-title class="title" style="font-weight: 500">
            Incident Disposition Report
          </v-card-title>
          <div class="d-flex justify-end">
            <template>
              <DateTimeFilter
                v-model="dateRange"
                :ranges="['Today', '2d', '1w', '1m']"
                custom
                inverted
                time-zone="America/New_York"
              />
              <IncidentFilter class="mr-4" @apply="applyFilter" />

              <div style="min-width: 240px" class="mr-2">
                <SearchField v-model="tableState.search" />
              </div>
            </template>
          </div>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="incidents"
              :custom-filter="customFilter"
              :custom-sort="customSort"
              :search="search"
              :sort-by.sync="tableState.sortBy"
              :sort-desc.sync="tableState.sortDesc"
              :page.sync="tableState.page"
              :items-per-page.sync="tableState.itemsPerPage"
              :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
              class="custom-table elevation-1"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    <ButtonView title="View" @click="gotoZone(item)" />
                  </td>
                  <td style="min-width: 120px">{{ item.shortId }}</td>
                  <td style="min-width: 120px">{{ item.shortCode }}</td>
                  <td style="min-width: 200px">
                    <div>
                      <strong>Facility</strong> : {{ item.incidentLocation }}
                    </div>
                    <div class="mt-2">
                      <strong>Smart Unit / Zone</strong> : {{ item.zoneName }}
                    </div>
                  </td>
                  <td>{{ item.incidentType }}</td>
                  <td>
                    <div>
                      {{ item.incidentDisposition }}
                      <div
                        v-if="item.isOverdue"
                        class="overdue-text mt-1 justify-center"
                      >
                        Overdue!
                      </div>
                    </div>
                  </td>
                  <td>
                    <SeverityChip :severity="titleCase(item.priority)" />
                  </td>
                  <td>{{ item.responder }}</td>
                  <td>{{ formatDateTime4Humans(item.startedAt) }}</td>
                  <td>{{ item.dueAt }}</td>
                  <td>{{ item.statusText }}</td>
                </tr>
              </template>

              <template #footer.prepend>
                <ButtonExportCSV
                  class="order-last"
                  :table-data="incidents"
                  :headers="headers"
                  filename="Incident Disposition Report"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router/composables";
import useUser from "@components/authentication/useUser";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import useDashboard from "../../dashboard/useDashboard";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonView from "@atoms/ButtonView.vue";

import { INCIDENT_HISTORY } from "../../../components/reports/graphql";
import useDataTable from "../../common/useDataTable";
import {
  incidentStatus,
  formatIncidentType,
} from "../../common/helpers/incident";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { diff, durationInWords } from "@tod-ui/helpers/datetime";
import { stringSortCollator } from "@tod-ui/helpers/strings";
import useFacility from "../../authentication/useFacility";
import { titleCase } from "@tod-ui/helpers/strings";
import IncidentFilter from "../../reports/IncidentFilter.vue";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";
import MonitorTileCards from "./MonitorTileCards.vue";
export default {
  name: "PageMonitorAndControlHub",
  components: {
    RegionAndFacilitySelect,
    LayoutPage,
    DateTimeFilter,
    SearchField,
    ButtonExportCSV,
    ButtonView,
    SeverityChip,
    IncidentFilter,
    MonitorTileCards,
  },
  setup() {
    const router = useRouter();
    const { defaultOrganization } = useUser();
    const defaultOrganizationName = computed(
      () => defaultOrganization.value?.name
    );
    const { organizationsLoading, selectedFacilities } = useDashboard();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["startedAt"],
      sortDesc: [true],
    });
    const {
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
    } = useFacility();

    return {
      defaultOrganization,
      defaultOrganizationName,
      organizationsLoading,
      selectedFacilities,
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
      tableState,
      showAllItems,
      restoreItemsPerPage,
    };
  },
  data() {
    return {
      loading: false,
      showRegionFacilitySelectBoxes: true,
      search: null,
      dateRange: { after: undefined },
      filter: {},
      incidentInfo: {},
      headers: [
        { text: "Details", value: "details" },
        { text: "Incident ID", value: "shortId" },
        { text: "Incident Code", value: "shortCode" },
        { text: "Incident Location", value: "task_location" },
        { text: "Incident Type", value: "incident_type" },
        { text: "Incident Disposition", value: "incident_disposition" },
        { text: "Priority", value: "severity" },
        { text: "Renter Details", value: "renter_details" },
        { text: "Started On", value: "started_on" },
        { text: "Target Close Date", value: "target_close_date" },
        { text: "Status", value: "status" },
      ],
    };
  },

  apollo: {
    incidents: {
      query: INCIDENT_HISTORY,
      variables() {
        return {
          parentId: this.defaultOrganization.id,
          dateTimeFilter: this.dateRange,
          ...this.filter,
        };
      },
      update({ incidents }) {
        const today = new Date().toISOString().split("T")[0];
        const yesterday = new Date(Date.now() - 86400000)
          .toISOString()
          .split("T")[0];

        const selectedAssigneeId = this.selectedAssigneeId;
        const selectedDisposition = this.selectedDisposition;
        let filteredIncidents = incidents.filter((incident) => {
          if (incident.priority === "default") {
            return false;
          }
          if (selectedAssigneeId && selectedAssigneeId !== "") {
            const hasMatchingAssignee = incident.tasks.some(
              (task) => task.assignee && task.assignee.id === selectedAssigneeId
            );
            if (!hasMatchingAssignee) {
              return false;
            }
          }
          if (selectedDisposition && selectedDisposition !== "") {
            if (incident.disposition !== selectedDisposition) {
              return false;
            }
          }
          if (this.filter.severity && this.filter.severity.length > 0) {
            if (
              !this.filter.severity.includes(incident.priority?.toLowerCase())
            ) {
              return false;
            }
          }
          return true;
        });
        if (this.filter.isOverdue) {
          filteredIncidents = filteredIncidents.filter((incident) =>
            incident.tasks?.some(
              (task) =>
                new Date(task.dueAt) < new Date() &&
                task.resolution !== "completed"
            )
          );
        }

        return filteredIncidents
          .filter((incident) => {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const status = searchParams.get("status");

            return incident.organization.ancestors.some((ancestor) =>
              this.selectedFacilities.some(
                (facility) => facility.id === ancestor.id
              )
            );
          })
          .map((incident) => {
            const responder = incident.responders?.length
              ? incident.responders.sort(
                  (a, b) => a.escalationLevel - b.escalationLevel
                )[0]
              : "--";

            const endedAt =
              incident.lastUntriggeredAt ||
              incident.closedAt ||
              incident.resolvedAt;
            const isOverdue = incident.tasks?.some(
              (task) =>
                new Date(task.dueAt) < new Date() &&
                task.resolution !== "completed"
            );

            return {
              ...incident,
              shortId: incident.shortId,
              shortCode: incident.shortCode,
              incidentLocation: incident.alerting_strategy.facility_org.name,
              zoneName: incident.alerting_strategy.device_org.name,
              incidentType: formatIncidentType(incident),
              incidentDisposition: incident.disposition,
              priority: incident.priority,
              responder: this.formatResponder(responder),
              startedAt: incident.startedAt,
              endedAt,
              duration: diff(incident.startedAt, endedAt).as("minutes"),
              statusText: incidentStatus(incident),
              dueAt:
                incident.tasks?.length > 0
                  ? this.facilityFormatDateTime4Humans(incident.tasks[0]?.dueAt)
                  : "NA",
              isOverdue,
            };
          });
      },
    },
  },
  watch: {
    selectedFacilities: "loadIncidentHistory",
  },
  mounted() {
    this.loadIncidentHistory();
  },
  methods: {
    gotoZone(incident) {
      this.incidentInfo.value = incident;
      this.$router.push({
        name: "PageIncidentInfo",
        params: {
          organization_id: incident.organization.ancestors[0].id,
          incident_id: incident.id,
        },
      });
    },
    formatDateTime4Humans(datetime) {
      return this.facilityFormatDateTime4Humans(datetime);
    },

    applyFilter(filter) {
      const _filter = { ...filter };

      _filter.organizationSubtypes = _filter.locations;
      delete _filter.locations;

      if (_filter.severityStatus?.length) {
        _filter.severity = _filter.severityStatus;
      }
      delete _filter.severityStatus;
      if (_filter.overdueStatus?.length) {
        _filter.isOverdue = _filter.overdueStatus.includes("overdue");
      }
      delete _filter.overdueStatus;

      if (_filter.statuses?.length) {
        _filter.resolutions = [];

        if (_filter.statuses.includes("authorized")) {
          _filter.resolutions.push("claimed");
        }

        if (_filter.statuses.includes("unauthorized")) {
          _filter.resolutions.push("denied");
          _filter.resolutions.push("surveyed");
        }

        if (_filter.statuses.includes("unacknowledged")) {
          _filter.resolutions.push("unexplained");
        }
      }
      delete _filter.statuses;

      for (let k in _filter) {
        if (_filter[k] === null) {
          delete _filter[k];
        } else if (typeof _filter[k] === "boolean" && !_filter[k]) {
          delete _filter[k];
        } else if (Array.isArray(_filter[k]) && !_filter[k].length) {
          delete _filter[k];
        }
      }

      if (_filter.afterHours) {
        _filter["duringAccessHours"] =
          _filter.afterHours === "after-hours" ? false : undefined;
      }

      this.filter = _filter;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      const _search = search.toLowerCase();
      const match = [
        item.zoneName.toLowerCase(),
        item.incidentLocation.toLowerCase(),
        item.incidentType.toLowerCase(),
        item.incidentDisposition.toLowerCase(),
        item.shortId.toLowerCase(),
        item.priority.toLowerCase(),
        item.responder.toLowerCase(),
        this.facilityFormatDateTime4Humans(item.startedAt),
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (_sortBy === "statusText") {
          // TODO: Give weights to status and resolutions to determine order
          const aStatus = `${a.status} ${a.resolution}`;
          const bStatus = `${b.status} ${b.resolution}`;

          return (bStatus < aStatus ? -1 : 1) * _sortDesc;
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    formatResponder(responder) {
      if (!responder?.name) return "--";
      if (!responder?.contactMethod?.value) return responder.name;
      return `${responder.name} @ ${formatPhoneNumber(
        responder.contactMethod.value
      )}`;
    },
    loadIncidentHistory() {
      this.$apollo.queries.incidents.refetch();
    },
    titleCase,
  },
};
</script>
