<template>
  <v-card
    :id="id"
    class="w-100 black-border d-flex flex-column justify-space-between h-100"
  >
    <v-card-text class="pt-4 pb-0 d-flex flex-column h-100">
      <v-row dense style="align-items: baseline">
        <v-col md="7" align="left">
          <h4 class="black--text text-md-h4 text-h5">
            <template v-if="loading">
              <v-progress-circular indeterminate color="black" />
            </template>
            <template v-else>
              <template v-if="title === 'Total Tasks'">
                <span class="font-weight-500 d-flex flex-wrap align-center">
                  <span class="text-opacity-87">{{ value.openTasks }}</span>
                  <v-tooltip v-if="value.overdueTasks > 0" top color="black">
                    <template #activator="{ on, attrs }">
                      <span
                        class="font-size-25 ml-1"
                        :style="{ color: color, opacity: 1 }"
                        v-bind="attrs"
                        v-on="on"
                      >
                        ({{ value.overdueTasks }}!)
                      </span>
                    </template>
                    <span>Overdue Tasks</span>
                  </v-tooltip>
                </span>
              </template>
              <template v-else
                ><span class="font-weight-500 text-opacity-87"
                  >{{ value }}
                </span></template
              >
            </template>
          </h4>
        </v-col>
        <v-col md="5" align="right">
          <div class="rounded-bg-tile">
            <v-icon :size="25" style="line-height: 0" color="white">
              {{ icon }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="12" align="left">
          <p class="black--text text-subtitle-1 text-opacity-87">
            {{ title }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn :color="color" block @click="$emit('click', id)">
      <v-row>
        <v-col align="left" md="9">
          <v-toolbar-title
            alight="left"
            class="body-2 white--text font-weight-bold"
          >
            View Details
          </v-toolbar-title>
        </v-col>
        <v-col md="3">
          <v-icon
            small
            align="right"
            class="icon-details"
            :class="{ open: details }"
            color="white"
            >fas fa-arrow-circle-right</v-icon
          >
        </v-col>
      </v-row>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#31708f",
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String, Number],
      required: true,
    },
  },
  computed: {
    tileBackgroundColor() {
      return this.color;
    },
  },
};
</script>

<style>
.rounded-bg-tile {
  background-color: v-bind(tileBackgroundColor);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-btn {
  max-height: 36px;
}
</style>
