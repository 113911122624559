<template>
  <LayoutPage>
    <template #title>
      <h1 class="headline mb-3"><FacilityName /></h1>
    </template>

    <v-row align="stretch" class="mb-3">
      <v-col md="12">
        <v-row class="w-100" style="align-items: stretch">
          <v-col>
            <InfoCard
              id="activeSmartUnits"
              v-model="activeSmartUnits.length"
              title="Active Smart Units"
              icon="fas fa-user-edit"
              :color="colors.activeSmartUnits"
              :loading="activeSmartUnitsLoading"
              :details="tableType == 'activeSmartUnits'"
              @click="setTableType"
            />
          </v-col>
          <v-col>
            <InfoCard
              id="authorizedAccesses"
              v-model="countIncidents.authorized"
              title="Authorized Accesses"
              icon="fas fa-check-square"
              :color="colors.authorizedAccesses"
              :details="tableType == 'authorizedAccesses'"
              @click="setTableType"
            />
          </v-col>
          <v-col>
            <InfoCard
              id="unauthorizedAccesses"
              v-model="countIncidents.unauthorized"
              title="Unauthorized Accesses"
              icon="fas fa-exclamation-triangle"
              :color="colors.unauthorizedAccesses"
              :details="tableType == 'unauthorizedAccesses'"
              @click="setTableType"
            />
          </v-col>
          <v-col>
            <InfoCard
              id="openAndOverdueTasks"
              v-model="taskInfoCard"
              icon="fa-sharp-duotone fa-light fa-clipboard-list-check"
              :color="colors.openAndOverdueTasks"
              :loading="activeSmartUnitsLoading"
              :details="tableType == 'openAndOverdueTasks'"
              title="Total Tasks"
              @click="setTableType"
            />
          </v-col>

          <v-col>
            <InfoCard
              id="unhealthyDefenders"
              v-model="leanUnhealthyDevices.length"
              title="Unhealthy Defenders"
              icon="fas fa-first-aid"
              :color="colors.unhealthyDefenders"
              :loading="leanUnhealthyDevicesLoading"
              :details="tableType == 'unhealthyDefenders'"
              @click="setTableType"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-expand-transition>
        <v-col v-if="tableType" md="12">
          <DeviceInfoTable
            v-if="tableType === 'activeSmartUnits'"
            :color="colors[tableType]"
          />
          <PageManageTasks
            v-else-if="tableType === 'openAndOverdueTasks'"
            :color="colors[tableType]"
          />
          <IncidentsInfoTable
            v-else-if="tableType === 'authorizedAccesses'"
            :authorized="true"
            :color="colors[tableType]"
          />
          <IncidentsInfoTable
            v-else-if="tableType === 'unauthorizedAccesses'"
            :authorized="false"
            :color="colors[tableType]"
          />
          <UnhealthyInfoTable
            v-else-if="tableType === 'unhealthyDefenders'"
            :color="colors[tableType]"
          />
        </v-col>
      </v-expand-transition>

      <v-col md="3">
        <RenterDemoForm class="dashboard-card" />
      </v-col>

      <v-col md="3">
        <CardRecentIncidents
          incident-type="active-units"
          class="dashboard-card"
        />
      </v-col>

      <v-col md="3">
        <CardRecentIncidents
          incident-type="vacant-units"
          class="dashboard-card"
        />
      </v-col>

      <v-col md="3">
        <CardRecentIncidents
          incident-type="after-hours"
          class="dashboard-card"
        />
      </v-col>
    </v-row>

    <FeatureWrapper feature="smart_zones">
      <v-row align="stretch">
        <v-col md="3">
          <InfoCard
            id="smartZoneServiceRequest"
            v-model="countZoneIncidents.serviceRequest"
            title="Service Requests"
            icon="mdi-gesture-tap"
            background-color="#75458c"
            :details="zoneTableType == 'smartZoneServiceRequest'"
            @click="setZoneTableType"
          />
        </v-col>
        <v-col md="3">
          <InfoCard
            v-if="zoneTrafficLoading"
            id="smartZoneTraffic"
            v-model="primaryZoneTraffic"
            title="Zone Traffic"
            icon="fas fa-users-class"
            background-color="#75458c"
            :loading="zoneTrafficLoading"
            :details="zoneTableType == 'zoneTrafficLoading'"
            @click="setZoneTableType"
          />
          <InfoCard
            v-else-if="primarySmartZone"
            id="smartZoneTraffic"
            v-model="primaryZoneTraffic"
            :title="`${facilityYesterdayDOW()}'s Primary Traffic`"
            icon="fas fa-users-class"
            background-color="#75458c"
            :loading="zoneTrafficLoading"
            :details="zoneTableType == 'smartZoneTraffic'"
            @click="setZoneTableType"
          />
          <InfoCard
            v-else
            id="smartZoneTraffic"
            value="0"
            :title="primarySmartZoneWarning"
            icon="fas fa-exclamation-triangle"
            background-color="warning"
            :details="zoneTableType == 'smartZoneTraffic'"
            @click="setZoneTableType"
          />
        </v-col>
        <v-col md="3">
          <InfoCard
            id="smartZoneMotion"
            v-model="countZoneIncidents.motion"
            title="Zone Motion"
            icon="fas fa-walking"
            background-color="#75458c"
            :details="zoneTableType == 'smartZoneMotion'"
            @click="setZoneTableType"
          />
        </v-col>
        <v-col md="3">
          <InfoCard
            id="smartZoneClimate"
            v-model="countZoneIncidents.climate"
            title="Zone Climate Alerts"
            icon="fas fa-temperature-hot"
            background-color="#75458c"
            :details="zoneTableType == 'smartZoneClimate'"
            @click="setZoneTableType"
          />
        </v-col>

        <v-expand-transition>
          <v-col v-if="zoneTableType" md="12">
            <ZoneInfoTable
              v-if="zoneTableType === 'activeSmartZones'"
              :loading="activeSmartZonesLoading"
              :zones="activeSmartZones"
            />
            <ZoneIncidentTable
              v-else-if="zoneTableType === 'smartZoneMotion'"
              incident-type="motion"
            />
            <ZoneIncidentTable
              v-else-if="zoneTableType === 'smartZoneClimate'"
              incident-type="climate"
            />
            <ZoneIncidentTable
              v-else-if="zoneTableType === 'smartZoneServiceRequest'"
              incident-type="service_request"
            />
            <ZoneTrafficGraph
              v-else-if="zoneTableType === 'smartZoneTraffic'"
            />
          </v-col>
        </v-expand-transition>
      </v-row>
    </FeatureWrapper>
  </LayoutPage>
</template>

<script>
import { yesterdayStartedAt, yesterdayEndedAt } from "@tod-ui/helpers/datetime";

import LayoutPage from "@layout/LayoutPage.vue";
import FacilityName from "@layout/FacilityName.vue";
import IncidentsInfoTable from "./IncidentsInfoTable.vue";
import DeviceInfoTable from "./DeviceInfoTable.vue";
import PageManageTasks from "@components/manage_tasks/PageManageTasks.vue";
import UnhealthyInfoTable from "./UnhealthyInfoTable.vue";
import InfoCard from "./InfoCard.vue";
import RenterDemoForm from "./RenterDemoForm.vue";
import CardRecentIncidents from "./CardRecentIncidents.vue";
import ZoneInfoTable from "./ZoneInfoTable.vue";
import ZoneIncidentTable from "./ZoneIncidentTable.vue";
import ZoneTrafficGraph from "./ZoneTrafficGraph.vue";
import FeatureWrapper from "@layout/FeatureWrapper.vue";
import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import {
  activeSmartUnits,
  activeSmartZonesDetailed,
} from "../../graphql/organizations";

import { leanUnhealthyDevices } from "../../graphql/incidents";
import useFacility from "../authentication/useFacility";
import useDashboardIncidents from "./useDashboardIncidents";
import { DASHBOARD_MONITORING_CONTROL_HUB_STATS_ITEM } from "./graphql";

export default {
  name: "PageDashboard",
  components: {
    LayoutPage,
    FacilityName,
    FeatureWrapper,
    InfoCard,
    IncidentsInfoTable,
    PageManageTasks,
    DeviceInfoTable,
    UnhealthyInfoTable,
    CardRecentIncidents,
    RenterDemoForm,
    ZoneInfoTable,
    ZoneIncidentTable,
    ZoneTrafficGraph,
    FacilityName,
  },
  setup() {
    const {
      currentFacility,
      currentFacilityId,
      facilityTimeZone,
      facilityYesterdayDOW,
      facilityFeatureEnabled,
    } = useFacility();
    const { countIncidents, countZoneIncidents, fetchCountFacilityIncidents } =
      useDashboardIncidents();
    return {
      currentFacility,
      currentFacilityId,
      facilityTimeZone,
      facilityYesterdayDOW,
      facilityFeatureEnabled,
      countIncidents,
      countZoneIncidents,
      fetchCountFacilityIncidents,
    };
  },
  data() {
    return {
      taskInfoCard: {
        overdueTasks: 0,
        openTasks: 0,
      },
      activeSmartUnits: [],
      leanUnhealthyDevices: [],
      tableType: null,
      zoneTableType: "smartZoneTraffic",
      activeSmartZones: [],
      isOverdue: false,
      zoneTraffic: [],
      colors: {
        openAndOverdueTasks: "#FF9E00",
        activeSmartUnits: "#0084FF",
        authorizedAccesses: "#0AC73C",
        unauthorizedAccesses: "#FF5533",
        unhealthyDefenders: "#989898",
      },
    };
  },
  computed: {
    activeSmartUnitsLoading() {
      return this.$apollo.queries.activeSmartUnits.loading;
    },
    activeSmartZonesLoading() {
      return this.$apollo.queries.activeSmartZones.loading;
    },
    zoneTrafficLoading() {
      return this.$apollo.queries.zoneTraffic.loading;
    },
    leanUnhealthyDevicesLoading() {
      return this.$apollo.queries.leanUnhealthyDevices.loading;
    },
    smartZonesLoading() {
      return (
        this.activeSmartZonesLoading ||
        this.zoneTrafficLoading ||
        !this.currentFacility?.profile
      );
    },
    primarySmartZone() {
      const primaryZoneId = this.currentFacility?.profile?.primaryZoneId;
      if (this.smartZonesLoading || !primaryZoneId) return null;

      return this.activeSmartZones.find(({ id }) => id === primaryZoneId);
    },
    primarySmartZoneWarning() {
      return this.currentFacility?.profile?.primaryZoneId
        ? "Primary Zone: No Assigned Devices"
        : "No Primary Zone Set";
    },
    primaryZoneTraffic() {
      if (this.zoneTrafficLoading || !this.primarySmartZone) return 0;

      return this.zoneTraffic.reduce((acc, event) => {
        return acc + (event.count || 0);
      }, 0);
    },
  },
  apollo: {
    activeSmartZones: {
      query: activeSmartZonesDetailed,
      skip() {
        return !this.facilityFeatureEnabled("smart_zones");
      },
      variables() {
        return {
          id: this.currentFacilityId,
          incident_statuses: ["started", "muted"],
          incident_resolution: "",
        };
      },
      // don't cache so we get the most real-time values for climate, etc.
      fetchPolicy: "no-cache",
      // filter out units w/o devices
      update: ({ smartZones }) =>
        smartZones.filter((o) => o.devices.length > 0),
    },
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      skip() {
        return (
          !this.facilityFeatureEnabled("smart_zones") ||
          !this.currentFacility.profile.primaryZoneId
        );
      },
      variables() {
        return {
          id: this.currentFacility.profile.primaryZoneId,
          dateTimeFilter: {
            after: yesterdayStartedAt(this.facilityTimeZone),
            before: yesterdayEndedAt(this.facilityTimeZone),
          },
          aggregate: "day",
        };
      },
    },
    activeSmartUnits: {
      query: activeSmartUnits,
      variables() {
        return { id: this.currentFacilityId };
      },
      fetchPolicy: "no-cache",
      update: ({ smartUnits }) =>
        smartUnits.filter((o) => o.devices.length > 0),
    },
    leanUnhealthyDevices: {
      query: leanUnhealthyDevices,
      variables() {
        return {
          parentId: this.currentFacilityId,
          disposition: "in_service",
          status: "lost_contact",
        };
      },
      fetchPolicy: "no-cache",
      pollInterval: 60000,
      update({ organizationDevices }) {
        // Filter only devices assigned to a zone
        return organizationDevices.filter(
          (device) =>
            device.organization.type === "zone" &&
            device.organization.responders.length > 0
        );
      },
    },
    monitoringControlHubStats: {
      fetchPolicy: "no-cache",
      query: DASHBOARD_MONITORING_CONTROL_HUB_STATS_ITEM,
      variables() {
        return {
          organizationIds: [this.currentFacilityId],
        };
      },
      result({ data }) {
        if (data && data.monitoringControlHubStats) {
          this.taskInfoCard.overdueTasks =
            data.monitoringControlHubStats.overdueTasks;
          this.taskInfoCard.openTasks =
            data.monitoringControlHubStats.openTasks;
          this.isOverdue = this.taskInfoCard.overdueTasks > 0;
          const currentUrl = new URL(window.location.href);
          const searchParams = currentUrl.searchParams;

          searchParams.set("overdue", this.isOverdue ? "true" : "false");
          searchParams.set("date", "all");
          searchParams.set("status", "all");

          window.history.pushState({}, "", currentUrl.toString());

          return this.taskInfoCard;
        } else {
          console.error("No monitoringControlHubStats data found", data);
        }
      },
    },
  },
  beforeMount() {
    this.fetchCountFacilityIncidents(this.$apollo);
  },
  methods: {
    setTableType(table) {
      this.tableType = this.tableType === table ? null : table;
      const currentUrl = new URL(window.location.href);
      const overdueParam = "overdue";
      console.log();

      this.isOverdue = this.taskInfoCard.overdueTasks > 0;
      if (this.tableType === "openAndOverdueTasks") {
        if (!currentUrl.searchParams.has(overdueParam)) {
          currentUrl.searchParams.set(
            overdueParam,
            this.isOverdue ? "true" : "false"
          );
          currentUrl.searchParams.set("date", "all");
          currentUrl.searchParams.set("status", "all");
          window.history.pushState({}, "", currentUrl.toString());
        }
      } else {
        if (currentUrl.searchParams.has(overdueParam)) {
          currentUrl.searchParams.delete(overdueParam);
          currentUrl.searchParams.delete("date");
          currentUrl.searchParams.delete("status");
          window.history.pushState({}, "", currentUrl.toString());
        }
      }
    },
    setZoneTableType(table) {
      this.zoneTableType = this.zoneTableType === table ? null : table;
    },
    viewFacilityProfile() {
      this.$router.push({
        name: "FacilityProfile",
      });
    },
    viewSmartUnits() {
      this.$router.push({
        name: "SmartUnits",
      });
    },
  },
};
</script>

<style scoped>
.dashboard-card {
  height: 100%;
}
</style>
