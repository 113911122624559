<template>
  <v-row>
    <v-col :md="overViewPage ? 6 : 12">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            :loading="organizationsLoading"
            type="image"
            :height="60"
          >
            <div ref="regionSelectRef">
              <v-card class="card-container" @click="handleRegionSelect">
                <div
                  v-if="selectedRegions.length"
                  class="d-flex justify-content-center align-items-center text-truncate"
                >
                  <div>
                    {{ selectedRegions[0].name }}
                  </div>
                  <div
                    v-if="selectedRegions.length > 1"
                    class="grey--text caption ml-2"
                  >
                    (+{{ selectedRegions.length - 1 }} others)
                  </div>
                </div>
                <v-icon size="40">mdi-menu-down</v-icon>
              </v-card>
              <v-card v-if="regionSelect" class="tree-container" elevation="5">
                <div class="d-flex align-center pl-3">
                  <v-checkbox v-model="allSelected" />
                  Select All
                </div>
                <TreeView
                  v-model="selectedRegions"
                  :tree-items="organizationTree"
                  :selected-items="selectedRegions"
                />
              </v-card>
            </div>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            :loading="organizationsLoading"
            type="image"
            :height="60"
          >
            <v-autocomplete
              v-if="facilitySingleSelect"
              v-model="selectedFacility"
              :items="facilities"
              label="Select Facility"
              dense
              outlined
              item-text="name"
              :return-object="true"
            />
            <v-autocomplete
              v-else
              v-model="selectedFacilities"
              :items="facilities"
              label="Select Facilities"
              dense
              multiple
              outlined
              clearable
              item-text="name"
              :return-object="true"
              clear-icon="far fa-times-circle"
            >
              <template #prepend-item>
                <v-list-item>
                  <v-checkbox
                    v-model="selectAllFacilities"
                    @change="toggleSelectAllFacilities"
                  />
                  <v-list-item-content>Select All</v-list-item-content>
                </v-list-item>
              </template>
              <template #selection="{ item, index }">
                <div v-if="index === 0" class="d-block text-truncate">
                  {{ item.name }}
                </div>
                <div v-if="index === 1" class="grey--text caption">
                  (+{{ selectedFacilities.length - 1 }} others)
                </div>
              </template>
            </v-autocomplete>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { ref, watch, onMounted, onUnmounted } from "vue";
import useUser from "@components/authentication/useUser";
import useDashboard from "./useDashboard";
import TreeView from "./management_summary/TreeView.vue";
export default {
  components: {
    TreeView,
  },
  props: {
    overViewPage: {
      type: Boolean,
      default: false,
    },
    facilitySingleSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { defaultOrganization } = useUser();
    const {
      fetchOrganizations,
      regions,
      facilities,
      selectedRegions,
      selectedFacilities,
      organizationsLoading,
      organizationTree,
      selectedFacility,
    } = useDashboard();
    const regionSelect = ref(false);
    const allSelected = ref(true);
    const regionSelectRef = ref(null);

    const selectAllFacilities = ref(false);

    function handleRegionSelect() {
      regionSelect.value = !regionSelect.value;
    }
    function handleClickOutside(event) {
      if (
        regionSelectRef.value &&
        !regionSelectRef.value.contains(event.target)
      ) {
        regionSelect.value = false;
      }
    }
    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });
    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleSelectAllFacilities(value) {
      if (value) {
        selectedFacilities.value = [...facilities.value];
      } else {
        selectedFacilities.value = [];
      }
    }

    watch(selectedFacilities, (newValue) => {
      selectAllFacilities.value = newValue.length === facilities.value.length;
    });

    watch(allSelected, (newValue) => {
      selectedRegions.value = newValue ? regions.value : [];
    });
    return {
      defaultOrganization,
      fetchOrganizations,
      regions,
      facilities,
      selectedRegions,
      selectedFacilities,
      organizationsLoading,
      organizationTree,
      allSelected,
      regionSelect,
      handleRegionSelect,
      regionSelectRef,
      selectAllFacilities,
      toggleSelectAllFacilities,
      facilitySingleSelect: props.facilitySingleSelect,
      selectedFacility,
    };
  },
  beforeMount() {
    this.fetchOrganizations(this.$apollo, this.defaultOrganization.id, [
      "region",
      "facility",
      "organization",
    ]);
  },
};
</script>
<style scoped>
.tree-container {
  background-color: white;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  width: 340px;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  padding-left: 15px;
}
</style>
